import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link, NavLink } from 'react-router-dom';
import PeopleSayImg from '../../Assets/images/leander/people-img1.png';
import Rating from '@mui/material/Rating';
import GoogleImg from '../../Assets/images/leander/google.png';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const UserReview = [
  // {
  //   username: 'Fernando A.',
  //   rating: 5,
  //   review: 'This is a locally owned jewelry store. Very high quality, handcrafted, one of a kind jewelry at fair market prices. You will receive friendly, personalized service. You will love the pieces you purchase.',
  //   url: 'https://www.yelp.com/biz/leander-jewelry-leander',
  //   source: 'Yelp Review',
  // },
  {
    username: 'Cesar Gudino',
    rating: 5,
    review: 'Awesome store! Family owned and very friendly staff! Got a really good deal on this solid gold rope chain. Highly recommend',
    url: 'https://www.google.com/search?q=leander+jewelry+co+tx&rlz=1C1UEAD_enIN948IN948&ei=dUBvZJHwH5WYseMPkYCluAQ&ved=0ahUKEwjR_sSKqZD_AhUVTGwGHRFACUcQ4dUDCA8&uact=5&oq=leander+jewelry+co+tx&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyCAghEBYQHhAdOgoIABBHENYEELADOhAIABDjBBDpBBDqAhC0AhgBOhUILhADEI8BEOoCELQCEIwDEOUCGAI6FQgAEAMQjwEQ6gIQtAIQjAMQ5QIYAjoICAAQigUQkQI6BwguEIoFEEM6BwgAEIoFEEM6CwgAEIoFELEDEIMBOgsIABCABBCxAxCDAToNCC4QxwEQ0QMQigUQQzoICAAQgAQQsQM6DQguEIoFEMcBENEDEEM6CgguELEDEIoFEEM6FQguEIoFEEMQlwUQ3AQQ3gQQ4AQYAzoICC4QigUQkQI6BQgAEIAEOgsILhCABBDHARCvAToLCC4QsQMQigUQkQI6CAguEIAEELEDOgsILhCABBCxAxCDAToNCC4QigUQxwEQrwEQQzoICC4QgAQQ1AI6BQguEIAEOgYIABAWEB46CAgAEIoFEIYDOgQIIRAVOgoIIRAWEB4QDxAdSgQIQRgAUJYEWN04YKU7aAJwAXgAgAGCAogBqiCSAQYwLjEzLjiYAQCgAQGwAQrAAQHIAQXaAQQIARgH2gEECAIYCtoBBggDEAEYFA&sclient=gws-wiz-serp#lrd=0x865b2b69f66ad7cb:0x3ffcd4c87e1f22ff,1,,,,',
    source: 'Google Review',
  },

  {
    username: 'Miguel Alba',
    rating: '5',
    review: "The people here are so helpful and nice! I have been to many jewelry stores and they are always pushing me to buy and I always feel pressured. This place helped me find what I wanted and I didn't feel pressured into purchasing a si gle thing! I love what they helped me find and they gave me a great deal too! 100% would recommend for everyone to visit and check them out!",
    url: 'https://www.google.com/search?q=leander+jewelry+co+tx&rlz=1C1UEAD_enIN948IN948&ei=dUBvZJHwH5WYseMPkYCluAQ&ved=0ahUKEwjR_sSKqZD_AhUVTGwGHRFACUcQ4dUDCA8&uact=5&oq=leander+jewelry+co+tx&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyCAghEBYQHhAdOgoIABBHENYEELADOhAIABDjBBDpBBDqAhC0AhgBOhUILhADEI8BEOoCELQCEIwDEOUCGAI6FQgAEAMQjwEQ6gIQtAIQjAMQ5QIYAjoICAAQigUQkQI6BwguEIoFEEM6BwgAEIoFEEM6CwgAEIoFELEDEIMBOgsIABCABBCxAxCDAToNCC4QxwEQ0QMQigUQQzoICAAQgAQQsQM6DQguEIoFEMcBENEDEEM6CgguELEDEIoFEEM6FQguEIoFEEMQlwUQ3AQQ3gQQ4AQYAzoICC4QigUQkQI6BQgAEIAEOgsILhCABBDHARCvAToLCC4QsQMQigUQkQI6CAguEIAEELEDOgsILhCABBCxAxCDAToNCC4QigUQxwEQrwEQQzoICC4QgAQQ1AI6BQguEIAEOgYIABAWEB46CAgAEIoFEIYDOgQIIRAVOgoIIRAWEB4QDxAdSgQIQRgAUJYEWN04YKU7aAJwAXgAgAGCAogBqiCSAQYwLjEzLjiYAQCgAQGwAQrAAQHIAQXaAQQIARgH2gEECAIYCtoBBggDEAEYFA&sclient=gws-wiz-serp#lrd=0x865b2b69f66ad7cb:0x3ffcd4c87e1f22ff,1,,,,',
    source: 'Google Review',
  },

  {
    username: 'Tabbetha DuVall',
    rating: 5,
    review: 'Awesome people and service! I was able to easily pick out a necklace and earring set for a funeral with the help of Mahin! They are so kind and fair in pricing, loved my experience! Super cute box included😁',
    url: 'https://www.google.com/search?q=leander+jewelry+co+tx&rlz=1C1UEAD_enIN948IN948&ei=dUBvZJHwH5WYseMPkYCluAQ&ved=0ahUKEwjR_sSKqZD_AhUVTGwGHRFACUcQ4dUDCA8&uact=5&oq=leander+jewelry+co+tx&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyCAghEBYQHhAdOgoIABBHENYEELADOhAIABDjBBDpBBDqAhC0AhgBOhUILhADEI8BEOoCELQCEIwDEOUCGAI6FQgAEAMQjwEQ6gIQtAIQjAMQ5QIYAjoICAAQigUQkQI6BwguEIoFEEM6BwgAEIoFEEM6CwgAEIoFELEDEIMBOgsIABCABBCxAxCDAToNCC4QxwEQ0QMQigUQQzoICAAQgAQQsQM6DQguEIoFEMcBENEDEEM6CgguELEDEIoFEEM6FQguEIoFEEMQlwUQ3AQQ3gQQ4AQYAzoICC4QigUQkQI6BQgAEIAEOgsILhCABBDHARCvAToLCC4QsQMQigUQkQI6CAguEIAEELEDOgsILhCABBCxAxCDAToNCC4QigUQxwEQrwEQQzoICC4QgAQQ1AI6BQguEIAEOgYIABAWEB46CAgAEIoFEIYDOgQIIRAVOgoIIRAWEB4QDxAdSgQIQRgAUJYEWN04YKU7aAJwAXgAgAGCAogBqiCSAQYwLjEzLjiYAQCgAQGwAQrAAQHIAQXaAQQIARgH2gEECAIYCtoBBggDEAEYFA&sclient=gws-wiz-serp#lrd=0x865b2b69f66ad7cb:0x3ffcd4c87e1f22ff,1,,,,',
    source: 'Google Review',
  },

  {
    username: 'Rajesh Pandya',
    rating: 5,
    review: 'I recently had the pleasure of visiting Leander Jewelry and I must say, I was thoroughly impressed. From the moment I walked in, I was greeted with warm smiles and exceptional customer service. The staff at Leander Jewelry were knowledgeable and eager to help me find the perfect piece of jewelry.',
    url: 'https://www.google.com/search?q=leander+jewelry+co+tx&rlz=1C1UEAD_enIN948IN948&ei=dUBvZJHwH5WYseMPkYCluAQ&ved=0ahUKEwjR_sSKqZD_AhUVTGwGHRFACUcQ4dUDCA8&uact=5&oq=leander+jewelry+co+tx&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyCAghEBYQHhAdOgoIABBHENYEELADOhAIABDjBBDpBBDqAhC0AhgBOhUILhADEI8BEOoCELQCEIwDEOUCGAI6FQgAEAMQjwEQ6gIQtAIQjAMQ5QIYAjoICAAQigUQkQI6BwguEIoFEEM6BwgAEIoFEEM6CwgAEIoFELEDEIMBOgsIABCABBCxAxCDAToNCC4QxwEQ0QMQigUQQzoICAAQgAQQsQM6DQguEIoFEMcBENEDEEM6CgguELEDEIoFEEM6FQguEIoFEEMQlwUQ3AQQ3gQQ4AQYAzoICC4QigUQkQI6BQgAEIAEOgsILhCABBDHARCvAToLCC4QsQMQigUQkQI6CAguEIAEELEDOgsILhCABBCxAxCDAToNCC4QigUQxwEQrwEQQzoICC4QgAQQ1AI6BQguEIAEOgYIABAWEB46CAgAEIoFEIYDOgQIIRAVOgoIIRAWEB4QDxAdSgQIQRgAUJYEWN04YKU7aAJwAXgAgAGCAogBqiCSAQYwLjEzLjiYAQCgAQGwAQrAAQHIAQXaAQQIARgH2gEECAIYCtoBBggDEAEYFA&sclient=gws-wiz-serp#lrd=0x865b2b69f66ad7cb:0x3ffcd4c87e1f22ff,1,,,,',
    source: 'Google Review',
  },

  {
    username: 'C Porterfield',
    rating: 5,
    review: 'Very kind people. I found a set of earrings I loved. They have quite a selection that even includes pieces for nose piercings! I appreciate that. And they do custom work! Looking forward to doing business with them again.',
    url: 'https://www.google.com/search?q=leander+jewelry+co+tx&rlz=1C1UEAD_enIN948IN948&ei=dUBvZJHwH5WYseMPkYCluAQ&ved=0ahUKEwjR_sSKqZD_AhUVTGwGHRFACUcQ4dUDCA8&uact=5&oq=leander+jewelry+co+tx&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyCAghEBYQHhAdOgoIABBHENYEELADOhAIABDjBBDpBBDqAhC0AhgBOhUILhADEI8BEOoCELQCEIwDEOUCGAI6FQgAEAMQjwEQ6gIQtAIQjAMQ5QIYAjoICAAQigUQkQI6BwguEIoFEEM6BwgAEIoFEEM6CwgAEIoFELEDEIMBOgsIABCABBCxAxCDAToNCC4QxwEQ0QMQigUQQzoICAAQgAQQsQM6DQguEIoFEMcBENEDEEM6CgguELEDEIoFEEM6FQguEIoFEEMQlwUQ3AQQ3gQQ4AQYAzoICC4QigUQkQI6BQgAEIAEOgsILhCABBDHARCvAToLCC4QsQMQigUQkQI6CAguEIAEELEDOgsILhCABBCxAxCDAToNCC4QigUQxwEQrwEQQzoICC4QgAQQ1AI6BQguEIAEOgYIABAWEB46CAgAEIoFEIYDOgQIIRAVOgoIIRAWEB4QDxAdSgQIQRgAUJYEWN04YKU7aAJwAXgAgAGCAogBqiCSAQYwLjEzLjiYAQCgAQGwAQrAAQHIAQXaAQQIARgH2gEECAIYCtoBBggDEAEYFA&sclient=gws-wiz-serp#lrd=0x865b2b69f66ad7cb:0x3ffcd4c87e1f22ff,1,,,,',
    source: 'Google Review',
  },

  {
    username: 'Mania Al',
    rating: 5,
    review: 'My husband brought me these two amazing pieces from Leander jewelry. I’m over the moon. They’re truly kind and honest people. I wish I could give them more than five stars. Will definitely be back.',
    url: 'https://www.google.com/search?q=leander+jewelry+co+tx&rlz=1C1UEAD_enIN948IN948&ei=dUBvZJHwH5WYseMPkYCluAQ&ved=0ahUKEwjR_sSKqZD_AhUVTGwGHRFACUcQ4dUDCA8&uact=5&oq=leander+jewelry+co+tx&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyCAghEBYQHhAdOgoIABBHENYEELADOhAIABDjBBDpBBDqAhC0AhgBOhUILhADEI8BEOoCELQCEIwDEOUCGAI6FQgAEAMQjwEQ6gIQtAIQjAMQ5QIYAjoICAAQigUQkQI6BwguEIoFEEM6BwgAEIoFEEM6CwgAEIoFELEDEIMBOgsIABCABBCxAxCDAToNCC4QxwEQ0QMQigUQQzoICAAQgAQQsQM6DQguEIoFEMcBENEDEEM6CgguELEDEIoFEEM6FQguEIoFEEMQlwUQ3AQQ3gQQ4AQYAzoICC4QigUQkQI6BQgAEIAEOgsILhCABBDHARCvAToLCC4QsQMQigUQkQI6CAguEIAEELEDOgsILhCABBCxAxCDAToNCC4QigUQxwEQrwEQQzoICC4QgAQQ1AI6BQguEIAEOgYIABAWEB46CAgAEIoFEIYDOgQIIRAVOgoIIRAWEB4QDxAdSgQIQRgAUJYEWN04YKU7aAJwAXgAgAGCAogBqiCSAQYwLjEzLjiYAQCgAQGwAQrAAQHIAQXaAQQIARgH2gEECAIYCtoBBggDEAEYFA&sclient=gws-wiz-serp#lrd=0x865b2b69f66ad7cb:0x3ffcd4c87e1f22ff,1,,,,',
    source: 'Google Review',
  },
];

const PeopleSay = () => {
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // var People = {
  //   dots: false,
  //   centerPadding: '60px',
  //   infinite: true,
  //   fade: true,
  //   speed: 500,
  //   centerMode: true,
  //   accessibility: true,
  //   arrows: true,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  //   autoplay: false,
  //   responsive: [
  //     {
  //       breakpoint: 1025,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4,
  //         centerPadding: '20px',
  //         infinite: true,
  //         dots: false,
  //       },
  //     },

  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4,
  //         centerPadding: '20px',
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4,
  //         arrows: false,
  //         initialSlide: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4,
  //         arrows: false,
  //         centerPadding: '0px',
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <section className="sj_whatPeopleSay_section px-5">
        {/* <Container> */}

        <div className="sj_whatPeople_title text-center">
          <h3>What People Say</h3>
        </div>

        {/*<Slider {...People} className="sj_people_say_slider">
            {UserReview?.map((customdata) => (
              <Col lg={12} className="">
                <div className="sj_people_slider_item">
                  <div className="sj_people_slide_content_left">
                    {/* <span className="sj_people_review_text"> Google Review </span> 

                    {isMobile ? (
                      <p>
                        {' '}
                        {customdata?.review?.slice(0, 100)}
                        {customdata?.review?.length > 100 ? '...' : ''}
                      </p>
                    ) : (
                      <p>
                        {' '}
                        {customdata?.review?.slice(0, 200)}
                        {customdata?.review?.length > 200 ? '...' : ''}
                      </p>
                    )}

                    <NavLink to="#" onClick={() => window.open(customdata?.url, '_blank')}>
                      {' '}
                      Read More{' '}
                    </NavLink>
                    <div>
                      <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly />
                    </div>
                    {/* <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly /> 

                    <div className="rew_main">
                      <h3>{customdata?.username}</h3>
                      <p>{customdata?.source}</p>
                    </div>
                  </div>

                  <div className="sj_people_slide_content_right">
                    <img src={PeopleSayImg} width="100%" height="100%" alt="custom design jewelry " />
                  </div>
                </div>
              </Col>
            ))}
          </Slider>
        </Container> */}

        <div className="rcs_customer_review_slider">
          <Slider className="rcs_customer_say_inner" {...settings2}>
            {UserReview?.map((customdata) => (
              <div className="rcs_customer_review_slide_item">
                <div className="rcs_customer_review_slide_item-inner">
                  <div className="rcs_customer_slide_title_img">
                    <div className="rcs_customer_review_slide_title">
                      <p>{customdata?.username}</p>
                      <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly />
                    </div>
                  </div>
                  <div className="rcs_customer_slide_text_content">
                    <p>
                      {customdata?.review?.slice(0, 150)}
                      {customdata?.review?.length > 150 ? '...' : ''}
                    </p>
                    <Link to="#" onClick={() => window.open(customdata?.url, '_blank')}>
                      {' '}
                      Read More{' '}
                    </Link>
                    <LazyLoadImage src={GoogleImg} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <div className="clearfix"></div>
    </>
  );
};
export default PeopleSay;
