import React from 'react';
import setting from '../../Assets/images/leander/setting.webp';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const CreateOwnDiamond = () => {
  const history = useHistory();
  return (
    <>
      <section className="create_own_diamond_ring">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 align-self-center">
              <div className="right-content">
                <p className='li_create_heading'>
                  <span>Create Your Own</span>{' '}
                </p>
                <h1>
                  <strong>Diamond Ring</strong>
                </h1>

                <p>
                  Select a setting and choose a diamond to create your own
                  diamond engagement ring.
                </p>
                <p className="mb-2">
                  <strong>Need help?</strong> We're always here
                </p>
                <img className="d-md-none d-block" src={setting} alt="" />
                <div className="btn-groups cr_ring_btn">
                  <Button
                    className="rcs_fill_button rcs_btn_rd-0  btn btn-primary"
                    onClick={() => history.push('/engagementrings')}
                  >
                    {' '}
                    Start With A Setting{' '}
                  </Button>
                  <Button
                    className="rcs_fill_button rcs_btn_rd-0  btn btn-primary"
                    onClick={() => history.push('/diamonds')}
                  >
                    {' '}
                    Start With A Diamond{' '}
                  </Button>
                  {/* <Link className="rcs_fill_button rcs_btn_rd-0  btn btn-primary"  to="/engagementrings">Start with a setting <BsArrowRight /></Link>
                                <Link className="rcs_fill_button rcs_btn_rd-0  btn btn-primary" to="/diamonds">Start with a Diamond <BsArrowRight /></Link> */}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 text-center d-md-block d-none">
              <img className="" src={setting} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateOwnDiamond;
