import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, NavLink } from "react-router-dom";
import TitleShape from "../../Assets/images/leander/title_img.png";
import Collection1 from "../../Assets/images/leander/Ex/necklace1.webp";
import Collection2 from "../../Assets/images/leander/Ex/ring1.webp";
import Collection3 from "../../Assets/images/leander/Ex/earrings1.webp";
import Collection4 from "../../Assets/images/leander/Ex/engagement-ring1.webp";
import TitleImg from "../../Assets/images/leander/line-black.png";

const ExploreCollection = () => {
  var explore = {
    dots: false,
    infinite: false,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="sj_explore_collection_section">
        <Container fluid>
          <Row>
            <Col lg="12" col="12">
              <div className="sj_title_main rcs_ringstyle_title ">
                <h3> EXPLORE OUR COLLECTION </h3>
                <Image
                  width="200px"
                  height="100%"
                  src={TitleImg}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="p-0">
              <Slider
                {...explore}
                className="sj_explore_collection_slider"
                data-aos="fade-up"
              >
                <div
                  className="sj_explore_item_main"
                  data-aos="fade-up"
                  data-aos-duration="2100"
                >
                  <Image src={Collection4} alt="wedding rings leander" />
                  <div className="sj_explore_hover_content">
                    <div>
                      <div className="sj_explore_title">
                        <h3> Engagement Rings </h3>
                      </div>
                      <p>
                        {" "}
                        Express yourself by accessorizing your wrists. We have
                        sleek bracelets, tennis bracelets, bangles and many more
                        options to choose from.{" "}
                      </p>
                      <button className="sj_shop_fill_btn">
                        {" "}
                        <NavLink to="/engagementrings"> Shop Now </NavLink>{" "}
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="sj_explore_item_main"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <Image src={Collection1} alt="" />
                  <div className="sj_explore_hover_content">
                    <div>
                      <div className="sj_explore_title">
                        <h3> Necklace </h3>
                      </div>
                      <p>
                        {" "}
                        Enhance your neck with our jaw dropping necklaces that
                        flatter a simple as well as bold look and looks good on
                        any attire.{" "}
                      </p>
                      <button className="sj_shop_fill_btn">
                        {" "}
                        <NavLink to="/jewelry/necklaces">
                          {" "}
                          Shop Now{" "}
                        </NavLink>{" "}
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="sj_explore_item_main"
                  data-aos="fade-up"
                  data-aos-duration="1100"
                >
                  <Image src={Collection2} alt="wedding rings leander" />
                  <div className="sj_explore_hover_content">
                    <div>
                      <div className="sj_explore_title">
                        <h3> Wedding Rings </h3>
                      </div>
                      <p>
                        {" "}
                        Find the perfect symbol of your love. Discover eternity
                        diamond rings, classic bands, and unique styles.{" "}
                      </p>
                      <button className="sj_shop_fill_btn">
                        {" "}
                        <NavLink to="/jewelry/wedding-rings">
                          {" "}
                          Shop Now{" "}
                        </NavLink>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="sj_explore_item_main"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <Image src={Collection3} alt="" />
                  <div className="sj_explore_hover_content">
                    <div>
                      <div className="sj_explore_title">
                        <h3> earrings </h3>
                      </div>
                      <p>
                        {" "}
                        Our earrings will add grace to your entire attire. We
                        have a wide range of hoops, studs, and drop earrings.{" "}
                      </p>
                      <button className="sj_shop_fill_btn">
                        {" "}
                        <NavLink to="/jewelry/earrings">
                          {" "}
                          Shop Now{" "}
                        </NavLink>{" "}
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div
                  className="sj_explore_item_main"
                  data-aos="fade-up"
                  data-aos-duration="1500">
                  <Image src={Collection3} alt="" />
                  <div className="sj_explore_hover_content">
                    <div>
                      <div className="sj_explore_title">
                        <h3> earrings </h3>
                      </div>
                      <p>
                        {" "}
                        Our earrings will add grace to your entire attire. We
                        have a wide range of hoops, studs, and drop earrings.{" "}
                      </p>
                      <button className="sj_shop_fill_btn">
                        {" "}
                        <NavLink to="/jewelry/earrings">
                          {" "}
                          Shop Now{" "}
                        </NavLink>{" "}
                      </button>
                    </div>
                  </div>
                </div> */}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ExploreCollection;
