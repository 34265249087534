import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import Ear from '../../Assets/images/leander/01.png';
import Neck from '../../Assets/images/leander/02.png';
const EarNeck = () => {
  return (
    <>
      <section className="Ear_Neck_section">
        <Container>
          <Row className="lookbook">
            <Col lg={6}>
              <div className="lookbookbox">
                <h2 className="title48  font-bold mrb15">Earrings</h2>
                <p className="title24 mrb15 li_look_sub_heading">Look your best every day!</p>
                <p>
                  Explore our exciting varieties of stylish earrings from short
                  and sweet to long and dramatic.
                </p>
                <div className="ttcmsoffer-btn">
                  <Link to="/jewelry/earrings">shop now</Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="lookbookright">
                <div className="lookbookright-bg lkbgright">
                  <div className="lookbookright-tag">new!</div>
                  <div className="image1 ttimg">
                    <div className="lookbookright-img">
                      <Link to="/jewelry/earrings">
                        <img width="100%" height="100%" src={Ear} alt="ring" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="lookbook mrt100 mrb100">
            <Col lg={6} className="order_item_2">
              <div className="lookbookright">
                <div className="lookbookright-bg lkbgright">
                  <div className="lookbookright-tag">new!</div>
                  <div className="image1 ttimg">
                    <div className="lookbookright-img">
                      <Link to="/jewelry/necklaces">
                        <img width="100%" height="100%" src={Neck} alt="ring" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="lookbookbox">
                <h2 className="title48  font-bold mrb15">Necklaces</h2>
                <p className="title24 mrb15 li_look_sub_heading">Look your best every day!</p>
                <p>
                  Find the perfect length for every occasion with classic pearl
                  strands, diamond elegance, colorful gemstones and more!
                </p>
                <div className="ttcmsoffer-btn">
                  <Link to="/jewelry/necklaces">shop now</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default EarNeck;
