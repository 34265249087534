
import { RiFacebookBoxFill , RiTwitterFill, RiInstagramLine, RiLinkedinBoxFill, RiYoutubeFill, RiPinterestFill} from "react-icons/ri";
import { Navigate, Outlet } from "react-router";
import Social1 from '../Assets/images/static/Social/1.png';
import Social2 from '../Assets/images/static/Social/2.png';
import Social3 from '../Assets/images/static/Social/3.png';
import Social4 from '../Assets/images/static/Social/4.png';
import Social5 from '../Assets/images/static/Social/5.png';
import Social6 from '../Assets/images/static/Social/6.png';
import Social7 from '../Assets/images/static/Social/7.png';
//-------------------------------------------------url
export const base_url = "https://leanderjewelry.co/webapi/api";
export const img_url = "https://leanderjewelry.co/";
export const helmet_url = "https://leanderjewelry.co/";

// ------Contact Details Start ------
export const contact_number = {                // <===---contact Number //
  text:'+1(512)-502-2440',   
  link:`tel:+15125022440`
};

export const Email_details = {                      // <===---Email_Details  //
  text:'leanderjewelry@gmail.com',
  link:`mailto:leanderjewelry@gmail.com`
};

export const Email_detailsSecond = {                // <===---Second_Email_details  // 
  text:'',
  link:``
};

export const Address_details = {                     // <===---Address_details  // 
  textLine1:'301 N Hwy 183 Suite A Leander,',
  textLine2:'TX 78641',
  link:"https://goo.gl/maps/GoxZDSBMPEJU9huq8"
};

export const client_name = {                          // <===---client_name  // 
  c_name:'Leander Jewelry LLC',
};

export const media_details = [                           // <===---media_details  // 
  {                                        
  account_alt:'Facbook',
  account_icon:<RiFacebookBoxFill />,
  account_img : Social1,
  account_link:"https://www.facebook.com/profile.php?id=100091827352257"
},
{                                        
  account_alt:'Instgram',
  account_icon:<RiInstagramLine />,
  account_img : Social2,
  account_link:"https://www.instagram.com/leanderjewelry/"
},
{                                        
  account_alt:'Pinterest',
  account_icon:<RiPinterestFill />,
  account_img : Social4,
  account_link:"https://www.pinterest.com/leanderjewelry/"
},
{                                        
  account_alt:'Youtube',
  account_icon: <RiYoutubeFill />,
  account_img : Social6,
  account_link:"https://www.youtube.com/channel/UCt0aUpcmH80KiRKiivBIOZA"
},
];


export const InvoiceAddress =()=> {              // <===---Invoice_Address  //
  return(
    <>
    <h4 className="mb-2">301 N Hwy 183 Suite A Leander, </h4>
    <h4 className="mb-0"> TX 78641</h4>
    </>
   )
  };
  // ------Contact Details End ------

export const user = JSON.parse(localStorage.getItem("bw-user")) ? JSON.parse(localStorage.getItem("bw-user")) : [];

export const isLogin = JSON.parse(localStorage.getItem("bw-user")) ? JSON.parse(localStorage.getItem("bw-user")).isLogin : false;
export const currencycode = localStorage.getItem("bw-currency") ? localStorage.getItem("bw-currency") : 'USD';
export const currency = localStorage.getItem("bw-currencyicon") ? localStorage.getItem("bw-currencyicon") : '$';

//--------------------------------------------------Api header data

export const postHeader = {
  // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const onTop = () => {
  window.scrollTo(0, 0);
}

export const ProtectedRoutes = () => {
  return isLogin ? <Outlet /> : <Navigate to="/" />
}
//---------------------------------------------------Ring builder
export const setSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-diamonddata')) ? JSON.parse(localStorage.getItem('bw-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-settingdata')) ? JSON.parse(localStorage.getItem('bw-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-fancycolordata')) : null;
  var arr;
   if (check == 0) {
    if (settingdata == null) {
       arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
       arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  }else if (check == 1) {
    if (settingdata == null) {
       arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
       arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
       arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
       arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
       arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
       arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
       arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
       arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
       arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
       arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } 
}
