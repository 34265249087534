import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Watch from '../../Assets/images/leander/Watch-Repair.png';
import Jewelery from '../../Assets/images/leander/Jewelry-Repair.png';
import TitleImg from '../../Assets/images/leander/line-black.png';
import Jrepair from '../../Assets/images/categories/jewelry-repair.jpg';
import CJDesign from '../../Assets/images/categories/custom-jewelry-design.jpg';
import WBReplacement from '../../Assets/images/categories/watch-better-replacement.jpg';
import CGBullion from '../../Assets/images/categories/coins-and-gold-bullion.jpg';
import Appraisals from '../../Assets/images/categories/appraisals.jpg';
import GBuying from '../../Assets/images/categories/gold-buying.jpg';
import POWatches from '../../Assets/images/categories/pre-owned-watches.jpg';
import ESJewelry from '../../Assets/images/categories/estate-jewelry.jpg';

const JewelryRepair = () => {
  return (
    <>
      <section className="jewelry_repair_section">
        <Container>
          <Row>
            <Col lg="12" col="12">
              <div className="sj_title_main rcs_ringstyle_title ">
                <h3> EXPLORE OUR CATEGORIES </h3>
                <Image width="200px" height="100%" src={TitleImg} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={4} md={6}>
              <div className="j_reapair_catelogbox">
                <Image src={Jrepair} alt="jewelry repair store in leander" />
                <div className="j_repair_text">
                  <h3> Jewelry Repair</h3>
                  <p> Our jewelry restoration services epitomize exquisite craftsmanship, revitalizing pieces ranging from delicate filigree to contemporary designs with unparalleled care and precision.</p>
                  <Link className="j_repair_btn" to="/jewelry-repair">
                    Learn More
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="j_reapair_catelogbox">
                <Image src={CJDesign} alt="Custom Jewelry Design" />
                <div className="j_repair_text">
                  <h3> Custom Jewelry Design</h3>
                  <p>Crafting bespoke beauty: our custom jewelry service combines your vision with expert craftsmanship. Elevate your style with unique pieces tailored just for you.</p>
                  <Link className="j_repair_btn" to="/custom-design">
                    Learn More
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="j_reapair_catelogbox">
                <Image src={WBReplacement} alt="Watch Battery Replacement" />
                <div className="j_repair_text">
                  <h3>Watch Battery Replacement</h3>
                  <p> Efficient watch battery replacement service ensures your timepiece runs smoothly. Quick, reliable, and hassle-free solution for your watch needs.</p>
                  <Link className="j_repair_btn" to="/watch-battery-replacement">
                    Learn More
                  </Link>
                </div>
              </div>
            </Col>
            {/* <Col lg={4} md={6}>
              <div className="j_reapair_catelogbox">
                <Image src={CGBullion} alt="Coins and Gold Bullion" />
                <div className="j_repair_text">
                  <h3>Coins and Gold Bullion </h3>
                  <p> You want to buy or sell gold coins we do both, we can special order we also have them in-store.</p>
                  <Link className="j_repair_btn" to="/">
                    Learn More
                  </Link> 
                </div>
              </div>
            </Col> */}
            <Col lg={4} md={6}>
              <div className="j_reapair_catelogbox">
                <Image src={Appraisals} alt="Appraisals" />
                <div className="j_repair_text">
                  <h3> Appraisals</h3>
                  <p>Accurate appraisals by certified experts. Thorough evaluations to determine the true value of your valuables. Reliable and trustworthy service. </p>
                  <Link className="j_repair_btn" to="/appraisals">
                    Learn More 
                  </Link> 
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="j_reapair_catelogbox">
                <Image src={GBuying} alt="Gold Buying" />
                <div className="j_repair_text">
                  <h3> Gold Buying</h3>
                  <p> Instant cash for your gold. Trust our expert appraisers for fair prices and seamless transactions. Quick, reliable, and hassle-free service.</p>
                  <Link className="j_repair_btn" to="/gold-buying">
                    Learn More
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="j_reapair_catelogbox">
                <Image src={POWatches} alt="Pre Owned Watches" />
                <div className="j_repair_text">
                  <h3>Pre Owned Watches</h3>
                  <p>Discover curated pre-owned watches. Each timepiece is meticulously inspected, offering quality and style at exceptional value. Explore our collection today.</p>
                  <Link className="j_repair_btn" to="/pre-owned-watches">
                    Learn More
                  </Link>
                </div>
              </div>
            </Col>
            {/* <Col lg={4} md={6}>
              <div className="j_reapair_catelogbox">
                <Image src={ESJewelry} alt="Estate Jewelry" />
                <div className="j_repair_text">
                  <h3>Estate Jewelry</h3>
                  <p>We buy all diamond jewelry, silver jewelry, and gold jewelry. Come in, get your jewelry appraised and if you want to sell it, we will give you a fair deal.</p>
                  <Link className="j_repair_btn" to="/jewelry-repair">
                    Learn More 
                  </Link> 
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
};
export default JewelryRepair;
