import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import '../../Assets/css/home.css';
import { isDesktop, isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { Button } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import { Col, Container, Image, Row, Carousel } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';
import BannerBg1 from '../../Assets/images/leander/Wedding-Days-Banner-1.webp';
import BannerBgMobile from '../../Assets/images/leander/Wedding-Days-mobile.jpg';
import GoldD from '../../Assets/images/leander/gold-d1.webp';
import GoldMobile from '../../Assets/images/leander/gold-mobile.jpg';
import FineD from '../../Assets/images/leander/fine.webp';
import FineMobile from '../../Assets/images/leander/fine-mobile.jpg';
import ValentineMobile from '../../Assets/images/leander/valentine-mobile.jpg';
import Valentine from '../../Assets/images/leander/valentine.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@mui/material';
import LazyLoad from 'react-lazy-load';
// import { Carousel, CarouselItem } from 'react-bootstrap';
// let sliderData = [
//   {
//     id:1,
//     imgLink: isMobileOnly ? ValentineMobile : Valentine,
//     title: '',
//     para: '',
//     BtnTitle: '',
//     BtnUrl: '/diamonds',
//   },
//   {
//     id:2,
//     imgLink: isMobileOnly ? BannerBgMobile : BannerBg1,
//     title: 'Engagement Rings',
//     para: 'The joy of wearing the best',
//     BtnTitle: 'Schedule an Appointment',
//     BtnUrl: '/make-an-appointment',
//   },
//   {
//     id:3,
//     imgLink: isMobileOnly ? GoldMobile : GoldD,
//     title: 'We buy gold',
//     para: '',
//     BtnTitle: 'Schedule an Appointment',
//     BtnUrl: '/make-an-appointment', 
//   },
//   {
//     id:4,
//     imgLink: isMobileOnly ? FineMobile : FineD,
//     title: 'Fine Jewelry',
//     para: '',
//     BtnTitle: 'Shop Now',
//     BtnUrl: '/jewelry/fine-jewelry',
//   },

// ];

const BannerSlider = () => {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //-----------------------------------Banner
    axios
      .get(base_url + `/home/slider`, {
        headers: postHeader,
      })
      .then((response) => {
        if (response.data.status == 1) {
          setBanner(response.data.data);
          localStorage.setItem('bw-bannerdata', JSON.stringify(response.data.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    accessibility: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        },
      },
    ],
  };

 
  return (
    <>
          <LazyLoad className='lazyLoad-heroBanner' height={isMobile?280:isTablet?362:762} width="100%" threshold={0.90}>
      <div className="main_slider">
        {loading ? (
          <Skeleton height={'680px'} style={{ transform: 'scale(1)' }} animation="wave" />
        ) : (
          <Carousel fade indicators={false}>
            {banner?.map((val, index) => (
              <Carousel.Item>
                <div className="rcs_hero_slider">
                  <div className="rcs_hero_slider_img">
                    <Image src={isMobileOnly ? val.mobile_image : val.image} alt="slider" className="img-fluid" />
                  </div>
                  <div className="rcs_hero_slider">
                    <div className="rcs_hero_img">
                      <Container fluid>
                        <Row className="m-auto w-100">
                          <Col md={8} className="m-auto">
                            <div className={isMobile ? 'text-center' : val.text_direction_class}>
                              <h2 data-aos="fade-up-left" data-aos-duration="700" className="banner_font">
                                {val.title}
                              </h2>
                              <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">
                                {val?.short_text}
                              </p>
                              <Button
                                data-aos="fade-up-left"
                                data-aos-duration="1800"
                                data-aos-delay="400"
                                onClick={() => {
                                  history.push(val.btn_link);
                                }}
                                variant="outline-dark"
                                >
                                {val.btn_text}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </div>
      </LazyLoad>

      {/* <div className="Lj_banner">
        <Slider
          {...settings}
          className="bannerSliderHome leander_banner_slider"
        >
          {sliderData?.map((val, index) => (
            <div className="rcs_hero_slider">
              <div className="rcs_hero_slider_img">
                <LazyLoadImage
                  src={val?.imgLink}
                  alt="slider Img"
                  className={index==0 ? "cursor_pointer img-fluid" : "img-fluid"}
                  onClick={()=> index == 0 ? history.push(val.BtnUrl ): ""}
                  
                />
              </div>
              <div className={index==0 ? "d-none":"rcs_hero_slider"} >
                <div className="rcs_hero_img">
                  <Container fluid>
                    <Row className="m-auto w-100">
                      <Col md={8} className="m-auto">
                        <div
                          className={
                            isMobile ? 'text-center' : val.text_direction_class
                          }
                        >
                          <h1
                            data-aos="fade-up-left"
                            data-aos-duration="700"
                            className="banner_font"
                          >
                            {val.title}
                          </h1>
                          <p
                            data-aos="fade-up-left"
                            data-aos-duration="1200"
                            data-aos-delay="300"
                          >
                            {val?.para}
                          </p>
                          <Button
                            data-aos="fade-up-left"
                            data-aos-duration="1800"
                            data-aos-delay="400"
                            onClick={() => {
                              history.push(val.BtnUrl);
                            }}
                            variant="outline-dark"
                          >
                            {val.BtnTitle}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}

      {/* <Slider {...settings}>
      <div className="rcs_hero_slider" style={{ backgroundImage: "url(" + BannerBg + ")" }}>
          <h1> Test 1 </h1>
        </div>

        <div>
          <h1> Test 1 </h1>
        </div>

        <div>
          <h1> Test 1 </h1>
        </div>

        <div>
          <h1> Test 1 </h1>
        </div>

  </Slider > */}

      {/* <Slider {...settings} className=' bannerSliderHome macau_banner_slider'>
      <div className="rcs_hero_slider" style={{ backgroundImage: "url(" + BannerBg + ")" }}>
        <div className="rcs_hero_img">
          <Container fluid>
            <Row className='m-auto w-100'>
              <Col md={8} className='m-auto'>
                <div className='text-center'>
                  <h1 data-aos="fade-up-left" data-aos-duration="700" className='banner_font'>Title</h1>
                  <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">Lorem ipsum</p>
                  <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" variant="outline-dark">Shop</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="rcs_hero_slider" style={{ backgroundImage: "url(" + BannerBg + ")" }}>
        <div className="rcs_hero_img">
          <Container fluid>
            <Row className='m-auto w-100'>
              <Col md={8} className='m-auto'>
                <div className='text-center'>
                  <h1 data-aos="fade-up-left" data-aos-duration="700" className='banner_font'>Title</h1>
                  <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">Lorem ipsum</p>
                  <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" variant="outline-dark">Shop</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="rcs_hero_slider" style={{ backgroundImage: "url(" + BannerBg + ")" }}>
        <div className="rcs_hero_img">
          <Container fluid>
            <Row className='m-auto w-100'>
              <Col md={8} className='m-auto'>
                <div className='text-center'>
                  <h1 data-aos="fade-up-left" data-aos-duration="700" className='banner_font'>Title</h1>
                  <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">Lorem ipsum</p>
                  <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" variant="outline-dark">Shop</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      </Slider> */}
    </>
  );
};

export default BannerSlider;
