import React, { useState , useEffect } from "react";
import "../../Assets/css/faq.css";
import { Col, Container, Row } from "react-bootstrap";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";



const Faq = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);
  const [expanded, setExpanded] = useState(null);




  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };


  return (
    <>
    <div>
      <Helmet>
         <script type="application/ld+json">
         {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": metaDetails?.faq?.map(item => ({
            "@type": "Question",
            "name": item?.title,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": item?.description
            }
          }))
        })}
         </script>
      </Helmet>
    </div>
      <div className="lj_faq_Section pt-5 pb-5">
        <Container>
          <h2 className="text-center mb-5">FAQs</h2>
          <Row>
            <Col sm={12} md={12} lg={12}>
              {metaDetails?.faq?.map((item, index) => (
                <Accordion
                  key={index + 1}
                  expanded={expanded === index + 1}
                  onChange={handleChange(index + 1)}
                  className="lj_faq_accordian"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${index + 1}-content`}
                    id={`panel-${index + 1}-header`}
                  >
                    <Typography
                      variant="p"
                      className="lj_faq_Question_heading"
                    >
                      {item?.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="lj_faq_Question_ans">
                      {item?.description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Faq;
