import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import setting from '../../Assets/images/leander/setting.webp';
import { useHistory } from 'react-router-dom';
import ConsultImg from '../../Assets/images/leander/couple-jewelry.webp';

const Consult = () => {
  const history = useHistory();

  return (
    <>
      <section className="consult_section">
        <Container className="rcs_custom_home_container ">
          <Row className="lookbook">
            <Col lg={5} className="align-self-center">
              <div className="right-content">
                <h3>
                  <strong>Consult Our Jewelry Specialist</strong>
                </h3>
                {/* <img className='d-md-none d-block img-fluid' src={ConsultImg} alt="" /> */}
                <p className="consult_p">
                  Our family owned jewelry store is dedicated to providing
                  exceptional service and quality jewelry to our customers. We
                  have been in the jewelry business for many years and are proud
                  to have earned a reputation for excellence in our community.
                </p>

                <div className="btn-groups cr_ring_btn consult">
                  <Button
                    className="rcs_fill_button rcs_btn_rd-0  btn btn-primary"
                    onClick={() => history.push('/contact')}
                  >
                    {' '}
                    Visit Our Store{' '}
                  </Button>
                  <Button
                    className="rcs_border_button rcs_btn_rd-0  btn btn-primary"
                    onClick={() => history.push('/make-an-appointment')}
                  >
                    {' '}
                    Book an Appointment{' '}
                  </Button>
                  {/* <Link className="rcs_fill_button rcs_btn_rd-0  btn btn-primary"  to="/engagementrings">Start with a setting <BsArrowRight /></Link>
        <Link className="rcs_fill_button rcs_btn_rd-0  btn btn-primary" to="/diamonds">Start with a Diamond <BsArrowRight /></Link> */}
                </div>
              </div>
            </Col>

            <Col lg={7} className="text-center ">
              <img
                className="img-fluid consult-img"
                width="100%"
                height="100%"
                src={ConsultImg}
                alt="custom jewelers and diamond jewelry store in leander"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Consult;
