import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../Assets/css/home.css';
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import Insta from './insta';
import ExploreCollection from './ExploreCollection';
import Virtualappointment from './VirtualAppointment';
import Customer from './PeopleSay';
import ShopDiamondShape from './ShopDiamondShape';
import Gemstones from './GemstoneShop';
import RingStyle from './RingStyle';
import Ringbuilderhome from './Ringbuilderhome';
import SignupTo from './SignupTo';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux';
import {  base_url, client_name, currency, currencycode, helmet_url, postHeader } from '../../Helpers/request';
import CreateOwnDiamond from './CreateOwnDiamond';
import PeopleSay from './PeopleSay';
import EarNeck from './EarNeck';
import Consult from './Consult';
import EstateBanner from './EstateBanner';
import JewelryRepair from './JewelryRepair';
import Faq from './Faq';
import { Button,InputGroup , Form, FormControl, Modal } from 'react-bootstrap';
// import PopImg from '../../Assets/images/leander-popup2.png';
import PopImgchange from '../../Assets/images/leanderpopup.png'; 
import PopImgchangemob from '../../Assets/images/mobleanderpopup.png';

import discount from '../../Assets/images/getpop.svg';


import { IoCloseSharp } from 'react-icons/io5';
import axios from 'axios';
import swal from 'sweetalert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Home = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);
  const [visible, setVisible] = React.useState(true);
 const [newsletteremail, setNewsletteremail] = useState("")
  const [loader,setLoader] = useState(false)
  useEffect(() => {
    AOS.init({
      duration: 1000,
      // disable: 'mobile'
    });
  }, []);

  const newsletter = (e) => {
      e.preventDefault();
      setLoader(true)
      const data = {
          currency_code: currencycode,
          email: newsletteremail
      }
      axios.post(base_url + '/common/subscribe_newsletter', data, {
          headers: postHeader
      })
          .then(res => {
              if (res.data.status == 1) {
                  setLoader(false)
                  setNewsletteremail('');
                  return swal(res.data.message, "", "success");
              } else {
                  setLoader(false)
                  return swal(res.data.message, "", "error");
              }
          })
          .catch((error) => {
              setLoader(false)
              console.log(error);
          });
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content={client_name.c_name} />
        <link rel="canonical" href="https://leanderjewelry.co/" />
      </Helmet>
      <BannerSlider />
      <CreateOwnDiamond />
      <EstateBanner />
      <ExploreCollection />
      <EarNeck />
      <JewelryRepair />
      <PeopleSay />
      <Consult />
      <Faq />
      {/* <Insta /> */}
      {/* <Ringbuilderhome />
      <ShopDiamondShape />      
      <Gemstones />
      <Virtualappointment/>      
      <Customer /> */}
      {/* <SignupTo /> */}
      {/* <AboutSection/> */}
      {/* <Colordiamond /> */}
      {/* <Letestcreation/> */}
      {/* <ServiceSection /> */}
      {/* <SignupTo /> */}
      {/* <Newsletter /> */}
      {/* <Featured /> */}
      {/*Collection1 :- Wedding band,Diamonds Earrings,Fine Jewelry */}
      {/* <Collection1 /> */}
      {/* <StateJewelry /> */}
      {/*Collection2 :- Pendants,Bracelets,Gemstones */}
      {/* <Collection2 /> */}
      {/* <IndianJewelry /> */}
      {/* <Philanthropy /> */}
      {/* <Testimonial /> */}
      {/* <Insta /> */}
      {/* <Newsletter /> */}

      <Modal className="info-home-popup" centered show={visible} onHide={()=>setVisible(false)} aria-labelledby="popup"
      >
        <Modal.Body className='lj_get_popup'>
          <div className='lj_imgpopup'>
            <Row>
              <Col lg={6} md={6} sm={6}>
                <div className='lj_imgbox'>
              <img src={PopImgchange} alt="" className='desktpop d-none d-sm-block'/> 
              <img src={PopImgchangemob} alt="" className='mobpop d-lg-none  d-md-none' /> 
              </div>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <div className='lj_text_box text-center'>
                  <div className='lj_couponbox'>
                    <div className='lj_pop_content'>
                    <img src={discount} alt="discount"  className="img-fluid" />
                    
                    <p>
                      On all subsrciptions now !Limited 
                    <br/>
                      time offer. Subscribe Today
                      <br/>
                      and save big.
                    </p>

                    {/* <p> copy: <b>$100</b></p> */}
                    </div>
                 
                  <div className="rcs_signup_to_input_field">
                  <form
                            className="form-newsletter3"
                            onSubmit={newsletter}
                          >
                            <div className="input-field">
                              <input
                                type="email"
                                placeholder='Enter Your email'
                                value={newsletteremail}
                                required
                                onChange={(e) =>
                                  setNewsletteremail(e.target.value)
                                }
                                aria-label="newsletter"
                              />
                            </div>
                            <div className="subscribe-filed">
                              <input
                                type="submit"
                                id="button-addon2"
                                defaultValue="Subscribe"
                                className="newsletter_signup"
                              />
                            </div>
                          </form>
                                 </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <button className="btn" aria-label="Close" onClick={() => setVisible(false)}>
            <IoCloseSharp />
          </button>
          {/* <img src={PopImg} alt="" />
          <div className="rcs_signup_to_input_field">
         
                                    <Form className="gs_news_form w-50" onSubmit={newsletter}>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                placeholder="Email Address..."
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                type="email"
                                                value={newsletteremail}
                                                required
                                                onChange={(e) => setNewsletteremail(e.target.value)}
                                            />
                                            <Button variant="outline-secondary" type="submit" id="button-addon2">
                                                Join
                                            </Button>
                                        </InputGroup>
                                    </Form>
                                 </div> */}
          {/* <div className='popup_model_email'>
              <input type="email" placeholder="Enter your email" className="form-control" required />
              <button className='popup_model_btn mt-2 '>Continue</button>
          </div> */}
        </Modal.Body>
      </Modal> 
    </>
  );
};

export default Home;
